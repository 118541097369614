* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

#navi-container {
  background-color: #282c34;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 10px 30px;
}

#navi-links li {
  display: inline;
  text-align: right;
}


#navi-links li a {
  display: inline-block;
  padding: 10px;
  text-decoration: none;
  font-size: x-large;
  color: #fff;
}

#navi-links li a:hover {
  cursor: pointer;
  background-color: #1d2027
}

#logo-text {
  display: flex;
  align-items: center;
  font-size: xx-large;
}

.large-card {
  height: 350px;
  width: 350px;
  border-radius: 4px;
  text-align: center;
  justify-content: center;
  border: 1px solid #000000;
  margin: 1rem 1rem;
  background-color: #fff;
}

.small-card {
  height: 250px;
  width: 250px;
  border-radius: 4px;
  text-align: center;
  justify-content: center;
  border: 1px solid #000000;
  margin: 1rem 1rem;
  background-color: #fff;
}

.large-card li {
  font-size: small;
  display: block;
  text-align: center;
  margin: 15px 0px;
}

.small-card li {
  font-size: small;
  display: block;
  text-align: center;
  margin: 15px 0px;
}

.card-container-one {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.card-container-two {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.card-container-three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

#home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0 10%;
  height: 100vh;
  width: 100%;
}

#welcome-msg {
  margin: 2rem 0;

}

#welcome-msg span {
  color: #FFA500;
}

#welcome-paragraph-first {
  display: flex;
  margin: 2rem 0;

}

#welcome-paragraph-second {
  height: auto;
  width: auto;
  display: flex;
}

#about-container {
  display: flex;
  background-color: #FFA500;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 150vh;
  width: 100%;
  padding: 0 10%;
}

.about-header {
  margin: 1rem 0;
}

.about-paragraph-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 1rem 0;
}

.about-paragraph-grid h4 {
  margin: 2rem 0;
}

#about-title {
  margin: 2rem 0;
}

#about-list li {
  margin: 2rem 0;
}

.list-title {
  align-items: left;

}

#youTube-videos {
  display: flex;
  margin: 2rem 0;
  padding: 0 10%;
  justify-content: space-between;
}

#services-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 150vh;
  padding: 0 10%;
}

.services-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 2rem 0;
}

.services-list h3 {
  margin: 2rem 0;
}

#gallery-container {
  display: flex;
  flex-direction: column;
  background-color: #FFA500;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 125vh;
  padding: 0 10%;
}

.contact-card {
  height: 222px;
  align-items: center;
  margin: 0 1rem;
}


#contact-title {
  display: flex;
  margin: 1rem 0;
}

#contact-head {
  display: flex;
  margin: 1rem 0;
}

#contact-sales {
  display: flex;
  margin: 1rem 0;
}

#sales-tele {
  display: flex;
  margin: 1rem 0;
}

#contact-service {
  display: flex;
  margin: 1rem 0;
}

#services-tele {
  display: flex;
  margin: 1rem 0;
}

#address {
  display: flex;
  margin: 1rem 0;
}

#footer-container {
  background-color: #282c34;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 50vh;
}

#foot li {
  display: inline;
  padding: 0 2rem;
}

#footer-end {
  margin: 2rem 0 0 0;
}

#damonmorgan {
  text-decoration: none;
}

@media screen and (max-width: 1216px) {
  .card-container-one {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 960px) {

  .large-card {
    margin: 2rem 0;
  }

  #about-container {
    height: 215vh;
  }

  #youTube-videos {
    flex-direction: column;
  }

  .card-container-one {
    grid-template-columns: 1fr;
    grid-gap: 5% 0%;
  }

  .card-container-three {
    grid-template-columns: 1fr 1fr;
    grid-gap: 5% 5%;
  }

  #services-container {
    height: 170vh;
  }

  .large-card {
    width: 100%;
    height: 100%;
  }

  .small-card {
    width: 100%;
    height: 100%;
  }

  #footer-container {
    height: 60vh;
  }

  #foot li {
    display: flex;
    padding: 1% 0;
  }

}

@media screen and (max-width: 640px) {
  #hamburger {
    display: flex;
    font-size: xx-large;
  }

  #home-container {
    height: 125vh;
  }

  #about-container {
    height: 300vh;
  }

  #hamburger:hover {
    cursor: pointer;
    background-color: #1d2027;
  }

  #navi-bar {
    max-width: 100%;
  }

  #navi-container {
    justify-content: space-evenly;
  }

  #youTube-videos {
    padding:0;
  }

  #navi-links {
    display: none;
  }

  .card-container-one {
    margin: 10% 0;
  }

  .card-container-two {
    grid-template-columns: 1fr;
  }


  .card-container-three {
    margin: 20% 0;
  }

  .small-card {
    padding: 10% 0 0 0;
  }

  .large-card {
    padding: 10% 0 0 0;
  }

  .contact-card {
    height:auto;
  }

  #footer-end {
    padding: 0 5%;
    font-size: small;
  }

  #footer-container {
    height: 90vh;
  }

}


@media screen and (min-width: 641px) {
  #hamburger {
    display:none;
  }

  
}